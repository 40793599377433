/**
 * 统一管理直营店项目后端接口的请求
 */
import request from './directRequest'

// 申请成为供应商
export function supplierApply(data) {
  return request({
    url: '/wx/apply/supplierApply',
    method: 'post',
    data
  })
}

// 申请成为采购商
export function purchaserApply(data) {
  return request({
    url: '/wx/apply/purchaserApply',
    method: 'post',
    data
  })
}

// 申请成为物流合作伙伴
export function logisticsApply(data) {
  return request({
    url: '/wx/apply/logisticsApply',
    method: 'post',
    data
  })
}

// 获取最新创建的苗圃
export function findNewestWarehouse(query) {
  return request({
    url: '/supplier/warehouse/latestBrief',
    method: 'get',
    params: query
  })
}

/* 平台数据 - 大屏数据展示 - 订单数据列表 */
export function getLargeScreenOrderList(params) {
  return request({
    url: '/wx/largeScreen/orderList',
    method: 'GET',
    params
  })
}

/* 平台数据 - 大屏数据展示 - 库存数据列表 */
export function getLargeScreenInventoryList(params) {
  return request({
    url: '/wx/largeScreen/inventoryList',
    method: 'GET',
    params
  })
}

/* 平台数据 - 大屏数据展示 - 视频数据列表 */
export function getLargeScreenVideoList(params) {
  return request({
    url: '/wx/largeScreen/videoList',
    method: 'GET',
    params
  })
}

/* 平台数据 - 大屏数据展示 - 苗圃数据列表 */
export function getLargeScreenWarehouseList(data) {
  return request({
    url: '/wx/largeScreen/warehouseList',
    method: 'POST',
    data
  })
}
