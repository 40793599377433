<template>
  <div class="business">
    <div class="container">
      <AddressPosition></AddressPosition>
      <div class="main">
        <div class="service-card-box">
          <div
            class="card"
            @mouseover="hoverCard(1)"
            @mouseleave="leaveCard(1)"
          >
            <div class="page-one" v-show="!toggle1">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-Vendor-bg1.png"
                fit="cover"
              ></el-image>
              <div class="page-txt">
                <div class="title">供应商合作伙伴</div>
                <div class="desc">
                  <p>您是否遇到过：</p>
                  <p>
                    1.采购商要求延期付款，交货后，又迟迟不按约定时间付全款；
                  </p>
                  <p>
                    2.辛苦培育的苗木，在规格最佳的时候没有采购订单，错过最佳时间，导致利润下降
                  </p>
                  <p>
                    3.难以获取市场上苗木的供需数据，因此无法准确选择种植利润高的苗木。
                  </p>
                </div>
              </div>
            </div>
            <div class="page-two" v-show="toggle1">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-Vendor-bg2.png"
                fit="cover"
              ></el-image>
              <div class="mask"></div>
              <div class="page-txt">
                <div class="title">供应商合作伙伴</div>
                <div class="sub-tit">我们的优势</div>
                <div class="icon-flex">
                  <div class="item">
                    <img :src="demoIMG.Vendor1" alt="" />
                    <div class="txt">现金支付</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.Vendor2" alt="" />
                    <div class="txt">订单充足</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.Vendor2" alt="" />
                    <div class="txt">行业大数据</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-apply" @click="openModal(1)">申请入驻</div>
          </div>
          <div
            class="card"
            @mouseover="hoverCard(2)"
            @mouseleave="leaveCard(2)"
          >
            <div class="page-one" v-show="!toggle2">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-purchaser-bg1.png"
                fit="cover"
              ></el-image>
              <div class="page-txt">
                <div class="title">采购商合作伙伴</div>
                <div class="desc">
                  <p>平台能为您带来：</p>
                  <p>1.平台拥有丰富的采购商资源，为您带来充足的订单；</p>
                  <p>2.平台提供便捷的交易管理工具，帮助您提高工作效率；</p>
                  <p>3.平台为物流货车安装监控装置，全程监控苗木安全。</p>
                </div>
              </div>
            </div>
            <div class="page-two" v-show="toggle2">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-purchaser-bg2.png"
                fit="cover"
              ></el-image>
              <div class="mask"></div>
              <div class="page-txt">
                <div class="title">采购商合作伙伴</div>
                <div class="sub-tit">我们的优势</div>
                <div class="icon-flex">
                  <div class="item">
                    <img :src="demoIMG.truck1" alt="" />
                    <div class="txt">效率提高</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.Vendor2" alt="" />
                    <div class="txt">订单充足</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.truck3" alt="" />
                    <div class="txt">物流监控</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-apply" @click="openModal(2)">申请入驻</div>
          </div>
          <div
            class="card"
            @mouseover="hoverCard(3)"
            @mouseleave="leaveCard(3)"
          >
            <div class="page-one" v-show="!toggle3">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-truck-bg1.png"
                fit="cover"
              ></el-image>
              <div class="page-txt">
                <div class="title">物流合作伙伴</div>
                <div class="desc">
                  <p>平台能为您带来：</p>
                  <p>1.平台拥有丰富的交易订单，为您带来充足的物流订单；</p>
                  <p>2.平台提供便捷的交易管理工具，帮助您提高工作效率；</p>
                  <p>
                    3.平台为物流货车安装监控装置，全程监控运输安全，减少非必要的纠纷；
                  </p>
                </div>
              </div>
            </div>
            <div class="page-two" v-show="toggle3">
              <el-image
                class="img"
                src="https://sc-seedling.oss-cn-hangzhou.aliyuncs.com/images/IMG-truck-bg2.png"
                fit="cover"
              ></el-image>
              <div class="mask"></div>
              <div class="page-txt">
                <div class="title">物流合作伙伴</div>
                <div class="sub-tit">我们的优势</div>
                <div class="icon-flex">
                  <div class="item">
                    <img :src="demoIMG.truck1" alt="" />
                    <div class="txt">效率提高</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.Vendor2" alt="" />
                    <div class="txt">订单充足</div>
                  </div>
                  <div class="item">
                    <img :src="demoIMG.truck3" alt="" />
                    <div class="txt">物流监控</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="btn-apply" @click="openModal(3)">申请入驻</div>
          </div>
        </div>
        <SectionTitle title="入驻伙伴"></SectionTitle>
        <div class="slide-box" ref="box">
          <div class="slide-list" ref="list1">
            <div class="slide-card" ref="card" v-for="(item, index) in CompanyDataArr" :key="index">
              <el-avatar
                class="head"
                :src="item.img"
                >logo</el-avatar
              >
              <div class="information">
                <div class="company-name">{{item.name}}</div>
                <div class="sub-info">
                  <div class="sub-tag">{{item.tag}}</div>
                  <div class="phone">{{item.phone}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      width="600px"
      :visible.sync="dialogFormVisible"
      :close-on-click-modal="false"
      :before-close="handleCloseApply"
    >
      <div class="modal-title">
        申请成为{{ typeName[formType - 1] }}合作伙伴
      </div>

      <el-form
        :model="ruleForm1"
        :rules="rules1"
        ref="ruleForm1"
        label-position="top"
        v-show="formType === 1"
        @validate="watchForm1"
      >
        <el-form-item
          label="姓名"
          prop="fullName"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm1.fullName"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="contactPhone"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm1.contactPhone"
            autocomplete="off"
            maxlength="11"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="苗圃面积（亩）"
          prop="nurseryArea"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm1.nurseryArea"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <el-form
        :model="ruleForm2"
        :rules="rules2"
        ref="ruleForm2"
        label-position="top"
        v-show="formType === 2"
        @validate="watchForm2"
      >
        <el-form-item
          label="姓名"
          prop="fullName"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm2.fullName"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="contactPhone"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm2.contactPhone"
            autocomplete="off"
            maxlength="11"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="年采购额"
          prop="annualProcurement"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm2.annualProcurement"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>

      <el-form
        :model="ruleForm3"
        :rules="rules3"
        ref="ruleForm3"
        label-position="top"
        v-show="formType === 3"
        @validate="watchForm3"
      >
        <el-form-item
          label="姓名"
          prop="fullName"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm3.fullName"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="联系电话"
          prop="contactPhone"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm3.contactPhone"
            autocomplete="off"
            maxlength="11"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item
          label="货车车型"
          prop="truckTypeDesc"
          :label-width="formLabelWidth"
        >
          <el-input
            class="input-style"
            v-model="ruleForm3.truckTypeDesc"
            autocomplete="off"
            clearable
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <div
          class="btn-submit"
          :class="allowSubmit ? '' : 'disabled'"
          @click="allowSubmit ? submit() : ''"
        >
          提交申请
        </div>
      </div>
    </el-dialog>
    <el-dialog
      :visible.sync="visibleTips"
      width="30%"
      :before-close="handleCloseTips"
    >
      <span>{{ tipsText }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="visibleTips = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { IsPC } from "@/utils/index.js";
import AddressPosition from "@/components/AddressPosition";
import SectionTitle from "@/components/SectionTitle.vue";
import imgSrc from "@/utils/img.js";
const demoIMG = imgSrc.data();

// import { findNewestWarehouse } from "@/api";
import { findNewestWarehouse } from "@/api/direct";
import {
  supplierApply,
  purchaserApply,
  logisticsApply
} from '@/api/direct'

export default {
  name: "Business",
  components: {
    AddressPosition,
    SectionTitle,
  },
  data() {
    const  valiPhone = (rule, value, callback) => {
      const reg = new RegExp(/^\d{11}$/)
      if (value && !reg.test(value)) {
        callback(new Error('联系电话应为11位数字'))
      } else {
        callback()
      }
    };
    const valiArea = (rule, value, callback) => {
      if (value === "") {
        // callback(new Error('苗圃面积不能为空'));
      } else {
        const reg = new RegExp(/^\d+$/)
        if (value && !reg.test(value)) {
          callback(new Error("苗圃面积必须为数字值"));
        }
        callback();
      }
    };
    const valiAnnualProcurement = (rule, value, callback) => {
      if (value === "") {
        // callback(new Error('苗圃面积不能为空'));
      } else {
        const reg = new RegExp(/^\d+$/)
        if (value && !reg.test(value)) {
          callback(new Error("年采购额必须为数字值"));
        }
        callback();
      }
    };
    return {
      demoIMG,
      toggle1: false,
      toggle2: false,
      toggle3: false,
      dialogFormVisible: false,
      formType: 1,
      typeName: ["供应商", "采购商", "物流"],
      formLabelWidth: "120px",
      ruleForm1: {
        fullName: "",
        contactPhone: "",
        nurseryArea: "",
      },
      ruleForm2: {
        fullName: "",
        contactPhone: "",
        annualProcurement: "",
      },
      ruleForm3: {
        fullName: "",
        contactPhone: "",
        truckTypeDesc: "",
      },
      rules1: {
        fullName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { validator: valiPhone },
        ],
        nurseryArea: [
          { required: true, message: "苗圃面积不能为空", trigger: "blur" },
          { validator: valiArea },
        ],
      },
      rules2: {
        fullName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { validator: valiPhone },
        ],
        annualProcurement: [
          { required: true, message: "年采购额不能为空", trigger: "blur" },
          { validator: valiAnnualProcurement },
        ],
      },
      rules3: {
        fullName: [
          { required: true, message: "姓名不能为空", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "联系电话不能为空", trigger: "blur" },
          { validator: valiPhone },
        ],
        truckTypeDesc: [
          { required: true, message: "货车车型不能为空", trigger: "blur" },
        ],
      },
      allowSubmit: false,
      visibleTips: false,
      tipsText: "",
      CompanyDataArr: [], // 渲染数据=实际数据*2
      // CompanyDataReal: [], // 实际数据
      offsetVal: 0
    };
  },
  watch: {
    $route() {
      console.log(this.$route.query.t);
      if (this.$route.query.t) {
        this.formType = parseInt(this.$route.query.t);
        this.dialogFormVisible = true;
      }
    },
  },
  beforeCreate() {
    if (!IsPC()) {
      this.$router.push({
        name: 'mBusiness'
      })
    }
  },
  mounted() {
    document.documentElement.scrollTop = 0;
    let type = parseInt(this.$route.query.t);
    if (type) {
      this.formType = type;
      this.dialogFormVisible = true;
    }
    this.$refs.box.onmousewheel = function() {
      return false
    }
    findNewestWarehouse().then(res=>{
      // console.log(res.data)
      let cdata = res.data.map(item=>{
        return {
          name: item.name,
          phone: item.contactPhone,
          img: item.avatar,
          tag: '供应商'
        }
      })
      // this.CompanyDataReal = cdata
      this.CompanyDataArr = cdata.concat(cdata)
      if (cdata.length > 3) {
        // 数据加载完且满足3条数据以上才滚动
        this.scrollCard()
      }
    })
  },
  methods: {
    hoverCard(val) {
      if (val === 1) {
        this.toggle1 = true;
      } else if (val === 2) {
        this.toggle2 = true;
      } else {
        this.toggle3 = true;
      }
    },
    leaveCard(val) {
      if (val === 1) {
        this.toggle1 = false;
      } else if (val === 2) {
        this.toggle2 = false;
      } else {
        this.toggle3 = false;
      }
    },
    openModal(type) {
      this.formType = type;
      this.dialogFormVisible = true;
      this.allowSubmit = false;
    },
    handleCloseApply() {
      let formName =
        this.formType === 1
          ? "ruleForm1"
          : this.formType === 2
          ? "ruleForm2"
          : "ruleForm3";
      this.$refs[formName].resetFields();
      this.$refs[formName].clearValidate();
      this.dialogFormVisible = false;
    },
    submit() {
      let formName =
        this.formType === 1
          ? "ruleForm1"
          : this.formType === 2
          ? "ruleForm2"
          : "ruleForm3";
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.formType === 1) {
            supplierApply(this.ruleForm1).then((res) => {
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
              this.allowSubmit = false;
              this.tipsText = "申请信息提交成功，请耐心等待平台审核";
              this.visibleTips = true;
            });
          } else if (this.formType === 2) {
            purchaserApply(this.ruleForm2).then((res) => {
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
              this.allowSubmit = false;
              this.tipsText = "申请信息提交成功，请耐心等待平台审核";
              this.visibleTips = true;
            });
          } else if (this.formType === 3) {
            logisticsApply(this.ruleForm3).then((res) => {
              this.$refs[formName].resetFields();
              this.dialogFormVisible = false;
              this.allowSubmit = false;
              this.tipsText = "申请信息提交成功，请耐心等待平台审核";
              this.visibleTips = true;
            });
          }
        } else {
          // console.log("error submit!!");
          // this.tipsText = '申请信息提交失败'
          // this.visibleTips = true
          return false;
        }
      });
    },
    handleCloseTips() {
      this.visibleTips = false;
    },
    watchForm(form) {
      let vali = true;
      for (let i in form) {
        if (form[i] === "") {
          vali = false;
        }
      }
      this.allowSubmit = vali;
    },
    watchForm1() {
      this.watchForm(this.ruleForm1);
    },
    watchForm2() {
      this.watchForm(this.ruleForm2);
    },
    watchForm3() {
      this.watchForm(this.ruleForm3);
    },
    scrollCard() {
      // let card = this.$refs.card
      let list1 = this.$refs.list1
      // let redundantLength = card.offsetWidth * (this.CompanyDataArr.length - this.CompanyDataReal.length)
      // console.log(list1.offsetWidth)
      // let redundantLength = list1.offsetWidth / 2
      if (Math.abs(this.offsetVal)>=list1.offsetWidth/2) {
        this.offsetVal = 0
        list1.style.left = `0px`
      } else {
        this.offsetVal = this.offsetVal - 1
        list1.style.left = `${this.offsetVal}px`
      }
      setTimeout(this.scrollCard, 30)
    }
  },
};
</script>

<style lang="less">
.business {
  .el-dialog__headerbtn {
    font-size: 26px;
  }
  .modal-title {
    font-size: 20px;
    color: #333;
    font-weight: 400;
    text-align: center;
    margin-bottom: 30px;
  }
  .btn-submit {
    width: 180px;
    height: 50px;
    background: linear-gradient(90deg, #00f260 0%, #0575e6 100%);
    box-shadow: 0px 8px 8px -4px rgba(17, 87, 53, 0.2);
    border-radius: 4px;
    opacity: 0.5;
    font-size: 16px;
    color: #fff;
    text-align: center;
    line-height: 50px;
    margin: 0 auto 30px;
    cursor: pointer;
    opacity: 1;
    &.disabled {
      opacity: 0.5;
    }
  }
  & > .container {
    width: 1200px;
    min-height: 1000px; /**待优化 */
    margin: 0 auto;
  }
  .main {
    .service-card-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .card {
        width: 384px;
        height: 600px;
        position: relative;
        box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.12);
        .page-one {
          .img {
            width: 384px;
            height: 600px;
          }
          .page-txt {
            position: absolute;
            top: 0;
            left: 0;
            padding: 24px;
            .title {
              font-size: 24px;
              color: #333;
              font-weight: bold;
              text-align: center;
              margin: 50px 0 40px;
            }
            .desc {
              font-size: 16px;
              color: #333;
            }
          }
        }
        .page-two {
          .img {
            width: 384px;
            height: 600px;
          }
          .mask {
            width: 384px;
            height: 600px;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
            top: 0;
            left: 0;
          }
          .page-txt {
            position: absolute;
            top: 0;
            left: 0;
            padding: 24px;
            .title {
              font-size: 24px;
              color: #fff;
              font-weight: bold;
              text-align: center;
              margin: 50px 0 40px;
            }
            .sub-tit {
              font-size: 16px;
              color: #fff;
              text-align: center;
            }
            .icon-flex {
              display: flex;
              justify-content: center;
              align-items: center;
              .item {
                width: 116px;
                & > img {
                  width: 116px;
                  height: 116px;
                }
                .txt {
                  font-size: 16px;
                  color: #fff;
                  text-align: center;
                }
              }
            }
          }
        }
        .btn-apply {
          width: 96px;
          height: 36px;
          background: linear-gradient(90deg, #00f260 0%, #0575e6 100%);
          box-shadow: 0px 8px 8px -4px rgba(17, 87, 53, 0.5);
          border-radius: 4px;
          position: absolute;
          bottom: 60px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 16px;
          color: #fff;
          text-align: center;
          line-height: 36px;
          z-index: 6;
        }
      }
    }
    .slide-box {
      height: 150px;
      // display: flex;
      // justify-content: flex-start;
      // align-items: center;
      overflow: hidden;
      position: relative;
      &::-webkit-scrollbar {
        display: none;
      }
      .slide-list {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
      }
      .slide-card {
        width: 336px;
        height: 60px;
        margin-right: 23px;
        padding: 20px 24px;
        background: linear-gradient(180deg, #ebf2f8 0%, #ffffff 100%);
        box-shadow: 8px 8px 24px 4px rgba(40, 60, 134, 0.08);
        border-radius: 8px;
        border: 2px solid #fff;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .head {
          width: 60px;
          height: 60px;
        }
        .information {
          margin-left: 16px;
          .company-name {
            font-size: 20px;
            font-weight: 400;
            color: #333;
            margin-bottom: 9px;
          }
          .sub-info {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            .sub-tag {
              width: 60px;
              height: 20px;
              line-height: 20px;
              border-radius: 2px;
              border: 1px solid #20a162;
              color: #20a162;
              text-align: center;
              margin-right: 10px;
            }
            .phone {
              font-size: 20px;
              font-weight: 400;
              color: #666;
            }
          }
        }
      }
    }
  }
  .input-style {
    .el-input__inner {
      height: 50px;
      line-height: 50px;
    }
  }
  .el-dialog__body {
    padding: 30px 50px;
  }
}
</style>